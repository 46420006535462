import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Image } from 'cloudinary-react';
import { motion } from 'framer-motion';
import * as yup from 'yup';
import cx from 'classnames';

import config from 'config';
import { useGlobal } from 'context/Global';
import scrollToFormError from 'lib/scrollToFormError';
import { makeNullable, optionalSlateAst } from 'lib/validators';
import Button from 'components/common/Button';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormImageUploader from 'components/form/FormImageUploader';
import FormLabel from 'components/form/FormLabel';
import FormInput from 'components/form/FormInput';
import FormSwitch from 'components/form/FormSwitch';
import FormTextEditor from 'components/form/FormTextEditor';
import FormTitle from 'components/form/FormTitle';
import FormNote from 'components/form/FormNote';

const GET_FUNDRAISER_STORY = gql`
  query GetFundraiserStoryData($id: String!) {
    findFundraisers(id: $id) {
      id
      campaign {
        id
        fundraiserDefaultStory
        fundraiserDefaultStoryTitle
        fundraiserDefaultHeaderBackgroundImage
        campaignHeaderBackgroundImage
      }
      displayName
      headerImage
      story
      storyTitle
    }
  }
`;

const UPDATE_FUNDRAISER_STORY = gql`
  mutation UpdateFundraiserStory($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      id
      headerImage
      story
      storyTitle
    }
  }
`;

const validationSchema = yup.object({
  headerImage: makeNullable(yup.string()),
  storyTitle: makeNullable(yup.string()),
  story: optionalSlateAst,
  displayName: makeNullable(yup.string()),
});

const AdminFundraiserEditModalStoryView = ({ fundraiserId, onDirtyChange, onCancel }) => {
  const client = useApolloClient();
  const { addToast } = useGlobal();
  const [isCustomName, setIsCustomName] = useState(false);
  const { control, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors, isDirty } = formState;

  const { data } = useQuery(GET_FUNDRAISER_STORY, {
    variables: { id: fundraiserId },
    onCompleted: ({ findFundraisers }) => setIsCustomName(!!findFundraisers[0].displayName),
  });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);

  const [updateFundraiser] = useMutation(UPDATE_FUNDRAISER_STORY);
  const onSubmit = useCallback(
    async (values) => {
      try {
        await updateFundraiser({
          variables: {
            data: {
              ...values,
              id: fundraiserId,
              displayName: isCustomName ? values.displayName : '',
            },
          },
        });
        client.refetchQueries({ include: ['GetFundraiserSnapshotSummary'] });

        addToast({
          id: 'fundraiser-edit',
          type: 'success',
          duration: 'short',
          message: 'Your changes have been saved',
        });
        reset({ ...values, id: fundraiserId, displayName: isCustomName ? values.displayName : '' });
      } catch (err) {
        if (config('/debug')) console.error(err);
        addToast({
          id: 'fundraiser-edit',
          type: 'error',
          duration: 'long',
          message: 'There was an error saving your changes, please try again.',
        });
      }
    },
    [updateFundraiser, reset, fundraiserId, isCustomName, client, addToast]
  );

  useEffect(
    () =>
      reset({
        headerImage: fundraiser?.headerImage ?? '',
        storyTitle: fundraiser?.storyTitle ?? campaign?.fundraiserDefaultStoryTitle ?? 'My Mission',
        story: fundraiser?.story ?? campaign?.fundraiserDefaultStory ?? null,
        displayName: fundraiser?.displayName ?? '',
      }),
    [reset, fundraiser, campaign]
  );

  // Prevent navigating with unsaved changes
  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!fundraiser) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToFormError)}>
      <FormTitle>Story</FormTitle>
      <FormFieldGroup>
        <FormNode className="max-w-2xs">
          <FormLabel className="hidden" htmlFor="headerImage">
            Cover Photo
          </FormLabel>
          <Controller
            control={control}
            name="headerImage"
            render={({ field }) => (
              <FormImageUploader
                aspectRatio={4 / 3}
                allowCrop
                placeholder={
                  campaign.fundraiserDefaultHeaderBackgroundImage ??
                  campaign.campaignHeaderBackgroundImage ??
                  config('/defaultFundraiserHeaderImage')
                }
                preview={({ image }) => (
                  <Image
                    publicId={image}
                    alt=""
                    width={800}
                    aspectRatio="4:3"
                    crop="fill"
                    fetchFormat="auto"
                    gravity="auto:faces"
                    className="block w-full rounded-xl border-4 border-white shadow-lg"
                  />
                )}
                editLabel
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="headerImage"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <FormLabel htmlFor="storyTitle">Page Title</FormLabel>
          <Controller
            control={control}
            name="storyTitle"
            render={({ field, fieldState }) => (
              <FormInput
                type="text"
                maxLength={250}
                status={fieldState.error ? 'error' : 'default'}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="storyTitle"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <FormLabel htmlFor="story">Story</FormLabel>
          <Controller
            control={control}
            name="story"
            render={({ field, fieldState }) => (
              <FormTextEditor status={fieldState.error ? 'error' : 'default'} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="story"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <div className="flex justify-between items-center">
            <label htmlFor="useDisplayName" className="cursor-pointer">
              Use Public Display Name
            </label>
            <FormSwitch
              value={isCustomName}
              name="useDisplayName"
              onChange={(isChecked) => setIsCustomName(isChecked)}
            />
          </div>
          <motion.div
            key="display-name-field"
            className={cx('my-4', { hidden: !isCustomName })}
            initial={false}
            animate={{ opacity: isCustomName ? 1 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <Controller
              control={control}
              name="displayName"
              render={({ field, fieldState }) => (
                <FormInput
                  type="text"
                  maxLength={250}
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
          </motion.div>
          <FormNote>
            Using a public display name will replace first and last name on your page, leaderboards,
            in search results, and all other instances.
          </FormNote>
          <ErrorMessage
            errors={errors}
            name="displayName"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
      </FormFieldGroup>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AdminFundraiserEditModalStoryView;

AdminFundraiserEditModalStoryView.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
