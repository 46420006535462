import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  faUserEdit,
  faTicket,
  faBullseyeArrow,
  faClipboardCheck,
  faUserFriends,
  faContactCard,
  faTimes,
  faShareNodes,
} from '@fortawesome/pro-regular-svg-icons';

import { useAuth } from 'context/Auth';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import MultiViewModal from 'components/common/MultiViewModal';
import AdminFundraiserEditModalContactView from './AdminFundraiserEditModalContactView';
import AdminFundraiserEditModalTicketView from './AdminFundraiserEditModalTicketView';
import AdminFundraiserEditModalStoryView from './AdminFundraiserEditModalStoryView';
import AdminFundraiserEditModalGoalView from './AdminFundraiserEditModalGoalView';
import AdminFundraiserEditModalDetailsView from './AdminFundraiserEditModalDetailsView';
import AdminFundraiserEditModalTeamView from './AdminFundraiserEditModalTeamView';
import AdminFundraiserEditModalSharingView from './AdminFundraiserEditModalSharingView';

const GET_PARTICIPANT = gql`
  query GetFundraiserForEdit($id: String!) {
    findParticipants(id: $id) {
      id
      type
      resolvedName
      userId
      answers {
        id
      }

      campaign {
        id
        myPermissions

        supportedFeatures {
          event
        }

        activeFeatures {
          teams
        }

        participantQuestions {
          id
        }
      }

      registrationType {
        id
        collectEmail
        collectPhone
        collectAddress
      }
    }
  }
`;

const AdminFundraiserEditModal = ({ id, onHide, initialView }) => {
  const { user } = useAuth();

  const { data } = useQuery(GET_PARTICIPANT, { variables: { id } });
  const participant = useMemo(() => data?.findParticipants[0], [data]);
  const campaign = useMemo(() => participant?.campaign, [participant]);
  const isEvent = campaign?.supportedFeatures.event;
  const isFundraiser = participant?.type === 'fundraiser';
  const isClaimedFundraiser = isFundraiser && !!participant?.userId;

  const canEditContact = useMemo(() => {
    if (!isEvent) return false;

    const { collectPhone, collectAddress } = participant.registrationType ?? {};
    if (isClaimedFundraiser && collectPhone === 'off' && collectAddress === 'off') {
      return false;
    }

    return true;
  }, [isEvent, isClaimedFundraiser, participant]);

  const isSelf = participant?.userId === user.id;
  const isCampaignManager = campaign?.myPermissions.includes('manageCampaign');
  const showTeam = campaign?.activeFeatures.teams;
  const hasDetails = campaign?.participantQuestions.length > 0;

  const views = useMemo(
    () => [
      ...(canEditContact ? [{ slug: 'contact', label: 'Contact', icon: faContactCard }] : []),
      ...(isFundraiser ? [{ slug: 'story', label: 'Story', icon: faUserEdit }] : []),
      ...(isFundraiser ? [{ slug: 'goals', label: 'Goals', icon: faBullseyeArrow }] : []),
      ...(isCampaignManager && isEvent
        ? [{ slug: 'ticket', label: 'Ticket', icon: faTicket }]
        : []),
      ...(isCampaignManager && hasDetails
        ? [{ slug: 'details', label: 'Details', icon: faClipboardCheck }]
        : []),
      ...(showTeam ? [{ slug: 'team', label: 'Team', icon: faUserFriends }] : []),
      ...(isFundraiser ? [{ slug: 'sharing', label: 'Sharing', icon: faShareNodes }] : []),
    ],
    [canEditContact, isFundraiser, isCampaignManager, hasDetails, showTeam, isEvent]
  );

  const [activeView, setActiveView] = useState(initialView);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState(null);

  useEffect(() => {
    if (!initialView && views.length > 0) {
      setActiveView(views[0].slug);
    }
  }, [initialView, views, setActiveView]);

  if (!participant) return null;

  if (!isFundraiser && !canEditContact && !hasDetails) {
    return (
      <Modal show onHide={onHide} className="max-w-lg">
        <Navbar
          left={
            <NavbarIconButton
              as="button"
              type="button"
              onClick={onHide}
              icon={faTimes}
              className="-ml-4"
            />
          }
          center={<NavbarTitle title={`Edit ${participant.resolvedName}`} />}
        />
        <div className="text-center p-6">
          <p>This participant has no information that can be edited.</p>
          <Button as="button" color="primary" className="font-medium mt-9 mb-6" onClick={onHide}>
            Done
          </Button>
        </div>
      </Modal>
    );
  }

  if (!activeView) return null;

  return (
    <MultiViewModal
      title={isSelf ? 'Edit My Profile' : `Edit ${participant.resolvedName}`}
      views={views}
      activeView={activeView}
      onViewChange={(slug) => {
        const fn = hasUnsavedChanges ? setConfirmNavigation : setActiveView;
        fn(slug);
      }}
      onHide={onHide}
      show
    >
      {activeView === 'contact' && (
        <AdminFundraiserEditModalContactView
          campaignId={campaign.id}
          participantId={participant?.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {activeView === 'ticket' && (
        <AdminFundraiserEditModalTicketView
          campaignId={campaign.id}
          participantId={participant?.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {activeView === 'story' && (
        <AdminFundraiserEditModalStoryView
          fundraiserId={participant.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {activeView === 'goals' && (
        <AdminFundraiserEditModalGoalView
          fundraiserId={participant.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {activeView === 'details' && (
        <AdminFundraiserEditModalDetailsView
          participantId={participant.id}
          campaignId={campaign.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {activeView === 'team' && (
        <AdminFundraiserEditModalTeamView
          fundraiserId={participant.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {activeView === 'sharing' && (
        <AdminFundraiserEditModalSharingView
          fundraiserId={participant.id}
          onDirtyChange={setHasUnsavedChanges}
          onCancel={onHide}
        />
      )}
      {confirmNavigation !== null && (
        <ConfirmDialog
          title="You have unsaved changes"
          confirmLabel="Yes, continue"
          declineLabel="No, go back"
          onConfirm={() => {
            setActiveView(confirmNavigation);
            setConfirmNavigation(null);
          }}
          onDecline={() => setConfirmNavigation(null)}
          show
        >
          <p>Are you sure you want to leave? Your changes will not be saved.</p>
        </ConfirmDialog>
      )}
    </MultiViewModal>
  );
};

AdminFundraiserEditModal.propTypes = {
  id: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  initialView: PropTypes.string,
};

AdminFundraiserEditModal.defaultProps = {
  initialView: null,
};

export default AdminFundraiserEditModal;
