import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import * as yup from 'yup';

import config from 'config';
import { makeNullable, slug } from 'lib/validators';
import useToasts from 'hooks/useToasts';
import FormTitle from 'components/form/FormTitle';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormInput from 'components/form/FormInput';
import FormNote from 'components/form/FormNote';
import Button from 'components/common/Button';

const GET_FUNDRAISER_SLUG = gql`
  query GetFundraiserSlug($id: String!) {
    findFundraisers(id: $id) {
      id
      slug
      campaignId
    }
  }
`;

const UPDATE_FUNDRAISER_SLUG = gql`
  mutation UpdateFundraiserSlug($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      id
      slug
    }
  }
`;

const AdminFundraiserEditModalSharingView = ({ fundraiserId, onDirtyChange, onCancel }) => {
  const toast = useToasts('edit-fundraiser-sharing');

  const [updateFundraiser] = useMutation(UPDATE_FUNDRAISER_SLUG, {
    onCompleted: () => toast.success('Your changes have been saved'),
    onError: (err) => {
      if (config('/debug')) {
        console.error(err);
      }
      toast.error('There was an issue saving your changes, please try again later');
    },
  });

  const { data } = useQuery(GET_FUNDRAISER_SLUG, {
    variables: { id: fundraiserId },
  });
  const fundraiser = data?.findFundraisers?.[0];

  const validationSchema = yup.object({
    slug: makeNullable(
      slug.uniqueSlug({
        scope: 'participant',
        objectId: fundraiser?.id,
        parentId: fundraiser?.campaignId,
      })
    ),
  });

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { errors, isDirty } = formState;

  const onSubmit = useCallback(
    async (values) => {
      await updateFundraiser({
        variables: { data: { ...values, id: fundraiserId } },
      });
    },
    [updateFundraiser, fundraiserId]
  );

  useEffect(() => {
    reset({
      slug: fundraiser?.slug ?? '',
    });
  }, [reset, fundraiser]);

  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!fundraiser) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldGroup>
        <FormNode>
          <FormTitle>Sharing</FormTitle>
          <FormLabel htmlFor="slug">Fundraising Page Vanity URL</FormLabel>
          <div className="relative flex items-center">
            <div className="flex justify-center items-center px-5 h-10 rounded-lg bg-gray-200 border border-gray-400 text-gray-500 mr-2">
              {`${config('/hostname')}...@`}
            </div>
            <div className="grow">
              <Controller
                control={control}
                name="slug"
                render={({ field, fieldState }) => (
                  <FormInput
                    placeholder="your-name"
                    status={fieldState.error ? 'error' : 'default'}
                    maxLength={64}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="slug"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
      </FormFieldGroup>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

AdminFundraiserEditModalSharingView.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AdminFundraiserEditModalSharingView;
