import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useKey } from 'rooks';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';

import useScreen from 'hooks/useScreen';
import useScrollLock from 'hooks/useScrollLock';
import AdminNavItem from 'components/admin/AdminNavItem';
import HSubnav from './HSubnav';
import HSubnavItem from './HSubnavItem';
import Navbar from './Navbar';
import NavbarIconButton from './NavbarIconButton';
import NavbarTitle from './NavbarTitle';
import Portal from './Portal';
import style from './MultiViewModal.module.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type MultiViewModalProps = PropsWithNonEmptyChildren<{
  onHide: () => void;
  show?: boolean;
  title?: ReactNode;
  views: {
    slug: string;
    label: NonEmptyReactNode;
    icon: IconProp;
  }[];
  activeView: string;
  onViewChange: (slug: string) => void;
  hideOnOverlayClick?: boolean;
  hideOnEscape?: boolean;
}>;

const MultiViewModal = ({
  views,
  onHide,
  activeView,
  onViewChange,
  children,
  show = false,
  title = null,
  hideOnOverlayClick = true,
  hideOnEscape = true,
}: MultiViewModalProps) => {
  const screen = useScreen();
  const scrollContainer = useRef(null);

  const handleOverlayClick = useCallback(() => {
    if (hideOnOverlayClick) onHide();
  }, [hideOnOverlayClick, onHide]);

  const handleEscape = useCallback(() => {
    if (hideOnEscape) onHide();
  }, [hideOnEscape, onHide]);

  useKey(['Escape'], handleEscape);
  useScrollLock(show);

  useEffect(() => scrollContainer.current?.scrollTo({ top: 0 }), [activeView]);

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <Portal id="modals">
      <AnimatePresence>
        {show && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 w-full h-full z-40 flex justify-center items-end lg:items-center overflow-y-auto"
          >
            <div className="fixed inset-0 bg-gray-900 opacity-75" onClick={handleOverlayClick} />
            <div
              className={cx(
                'relative bg-white w-full h-full max-w-7xl lg:rounded-xl overflow-hidden shadow-2xl flex flex-col',
                style.modalContainer
              )}
            >
              <Navbar
                className="h-16 bg-white border-b border-gray-400 shadow lg:shadow-none shrink-0"
                left={
                  <NavbarIconButton
                    as="button"
                    type="button"
                    icon={faTimes}
                    onClick={onHide}
                    className="-ml-2"
                  />
                }
                center={title ? <NavbarTitle title={title} /> : null}
              />
              <div className="flex-1 flex flex-col items-stretch lg:flex-row overflow-hidden">
                <div className="shrink-0 border-b border-gray-400 lg:border-b-0 lg:border-r lg:bg-gray-100 lg:w-1/4 min-w-2xs">
                  {screen.lg ? (
                    <nav className="overflow-auto p-8">
                      {views.map((view) => (
                        <AdminNavItem
                          key={view.slug}
                          as="button"
                          type="button"
                          label={view.label}
                          icon={view.icon}
                          isActive={view.slug === activeView}
                          onClick={() => onViewChange(view.slug)}
                        />
                      ))}
                    </nav>
                  ) : (
                    <HSubnav className="p-6 pb-0">
                      {views.map((view) => (
                        <HSubnavItem
                          key={view.slug}
                          as="button"
                          type="button"
                          isActive={view.slug === activeView}
                          activeColor="gray-800"
                          onClick={() => onViewChange(view.slug)}
                        >
                          {view.label}
                        </HSubnavItem>
                      ))}
                    </HSubnav>
                  )}
                </div>
                <div className="flex-1 overflow-y-auto p-6 lg:p-10" ref={scrollContainer}>
                  <div className="max-w-3xl mx-auto">{children}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default MultiViewModal;
