import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import * as yup from 'yup';

import config from 'config';
import { useGlobal } from 'context/Global';
import { makeNullable } from 'lib/validators';
import scrollToFormError from 'lib/scrollToFormError';
import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';
import FormDivider from 'components/form/FormDivider';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormLabel from 'components/form/FormLabel';
import FormNode from 'components/form/FormNode';
import FormSelect, { renderOption } from 'components/form/FormSelect';
import FormSwitch from 'components/form/FormSwitch';
import FormTitle from 'components/form/FormTitle';

const GET_FUNDRAISER_TEAM = gql`
  query GetFundraiserTeam($id: String!) {
    findParticipants(id: $id) {
      id
      type
      isTeamCaptain
      teamId
      campaign {
        id
        myPermissions
        teams(order: "name") {
          id
          name
          avatarImage
        }
      }
    }
  }
`;

const UPDATE_FUNDRAISER_TEAM = gql`
  mutation UpdateFundraiserTeam($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      id
      isTeamCaptain
      teamId
    }
  }
`;

const validationSchema = yup.object({
  teamId: makeNullable(yup.string()),
  isTeamCaptain: yup
    .boolean()
    .when(['$collectCaptain'], ([collectCaptain], schema) =>
      collectCaptain ? schema : schema.strip()
    ),
});

const AdminFundraiserEditModalTeamView = ({ fundraiserId, onDirtyChange, onCancel }) => {
  const { addToast } = useGlobal();

  const { data } = useQuery(GET_FUNDRAISER_TEAM, { variables: { id: fundraiserId } });
  const participant = useMemo(() => data?.findParticipants[0], [data]);
  const campaign = useMemo(() => participant?.campaign, [participant]);
  const teams = useMemo(() => campaign?.teams, [campaign]);

  const isFundraiser = participant?.type === 'fundraiser';
  const isCampaignManager = useMemo(
    () => campaign?.myPermissions.includes('manageCampaign'),
    [campaign]
  );

  const { control, handleSubmit, reset, watch, setValue, formState } = useForm({
    resolver: yupResolver(validationSchema),
    context: { collectCaptain: isCampaignManager },
  });
  const selectedTeam = watch('teamId');
  const { isDirty } = formState;

  const renderTeamOption = useCallback(
    (...args) => {
      const team = teams.find((x) => x.id === args[0].value);
      return (
        <div className="flex items-center">
          <Avatar
            size="xs"
            className="mr-2"
            image={
              team.avatarImage ??
              campaign.teamDefaultHeaderAvatarImage ??
              config('/defaultTeamAvatar')
            }
          />
          {renderOption(...args)}
        </div>
      );
    },
    [campaign, teams]
  );

  const [updateFundraiser] = useMutation(UPDATE_FUNDRAISER_TEAM);
  const onSubmit = useCallback(
    async (values) => {
      try {
        await updateFundraiser({ variables: { data: { id: fundraiserId, ...values } } });

        addToast({
          id: 'fundraiser-edit',
          type: 'success',
          duration: 'short',
          message: 'Your changes have been saved',
        });
      } catch (err) {
        if (config('/debug')) console.error(err);
        addToast({
          id: 'fundraiser-edit',
          type: 'error',
          duration: 'long',
          message: 'There was an error saving your changes, please try again.',
        });
      }
    },
    [updateFundraiser, fundraiserId, addToast]
  );

  useEffect(
    () =>
      reset({
        teamId: participant?.teamId ?? undefined,
        isTeamCaptain: participant?.isTeamCaptain ?? false,
      }),
    [reset, participant]
  );

  useEffect(() => {
    if (!participant) return;

    // Team has changed or been cleared, reset isTeamCaptain
    if (!selectedTeam || selectedTeam !== participant.teamId) {
      setValue('isTeamCaptain', false, { shouldDirty: true });
      return;
    }

    // Team changed back to original, re-apply saved isTeamCaptain
    setValue('isTeamCaptain', participant.isTeamCaptain, { shouldDirty: true });
  }, [selectedTeam, participant, setValue]);

  // Prevent navigating with unsaved changes
  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!participant) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToFormError)}>
      <FormTitle>Team</FormTitle>
      <FormFieldGroup>
        <FormNode>
          <FormLabel id="teamIdLabel" htmlFor="teamId">
            Team selection
          </FormLabel>
          <Controller
            control={control}
            name="teamId"
            render={({ field }) => (
              <FormSelect
                placeholder="Search teams..."
                options={teams.map((x) => ({ label: x.name, value: x.id }))}
                formatOptionLabel={renderTeamOption}
                aria-labelledby="teamIdLabel"
                {...field}
              />
            )}
          />
          {isCampaignManager && Boolean(selectedTeam) && isFundraiser && (
            <motion.div
              key="roles"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="mb-4"
            >
              <FormDivider />
              <div className="flex justify-between items-center">
                <label htmlFor="isTeamCaptain" className="cursor-pointer">
                  Designate as team captain
                </label>
                <Controller
                  control={control}
                  name="isTeamCaptain"
                  render={({ field }) => <FormSwitch {...field} />}
                />
              </div>
              <FormDivider />
            </motion.div>
          )}
        </FormNode>
      </FormFieldGroup>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AdminFundraiserEditModalTeamView;

AdminFundraiserEditModalTeamView.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
