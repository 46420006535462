import PropTypes from 'prop-types';
import cx from 'classnames';

const FormDivider = ({ className }) => (
  <div className={cx('my-6 md:my-8 h-px bg-gray-300', className)} />
);

FormDivider.propTypes = {
  className: PropTypes.node,
};

FormDivider.defaultProps = {
  className: '',
};
export default FormDivider;
