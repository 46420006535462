import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';
import * as yup from 'yup';

import config from 'config';
import { useGlobal } from 'context/Global';
import scrollToFormError from 'lib/scrollToFormError';
import { formatDateRange, parseNumber, formatCurrency } from 'lib/formatters';
import { makeRequired, makeNullable, number } from 'lib/validators';
import Button from 'components/common/Button';
import FormCurrencyInput from 'components/form/FormCurrencyInput';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormNumberInput from 'components/form/FormNumberInput';
import FormRadioGroup from 'components/form/FormRadioGroup';
import FormSelect from 'components/form/FormSelect';
import FormTitle from 'components/form/FormTitle';
import FormNote from 'components/form/FormNote';

const GET_FUNDRAISER_GOAL = gql`
  query GetFundraiserGoal($id: String!) {
    findFundraisers(id: $id) {
      id
      campaign {
        id
        assignedActivityMetrics {
          id
          category
          plural
        }
        isMetricLabelLocked
        isPerformanceEnabled
        metricLabel
        metricLabelPlural
        performanceEndDate
        performanceMode
        performanceStartDate
        performanceTrackingLevel
      }
      intervalReminderOffset
      monetaryGoal
      performanceEstimate
      performanceSettings {
        metricLabelPlural
      }
      unlockedActivityMetricId
      commitmentAmount
    }
  }
`;

const UPDATE_FUNDRAISER_GOAL = gql`
  mutation UpdateFundraiserGoal($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      id
      intervalReminderOffset
      monetaryGoal
      performanceEstimate
      performanceSettings {
        metricLabelPlural
      }
      unlockedActivityMetricId
    }
  }
`;

const positiveNumber = number.integer().positive(config('/validationMessages/positiveNumber'));
const validationSchema = yup.object({
  monetaryGoal: makeRequired(positiveNumber).when(
    ['$commitmentAmount'],
    ([commitmentAmount], schema) => {
      if (commitmentAmount) {
        return schema.min(
          commitmentAmount,
          `Your fundraising goal should be the same as or more than your fundraising commitment of ${formatCurrency(
            commitmentAmount
          )}`
        );
      }
      return schema;
    }
  ),
  performanceEstimate: number.when(['$collectEstimate'], ([collectEstimate], schema) =>
    collectEstimate ? makeRequired(positiveNumber) : schema.strip()
  ),
  unlockedActivityMetricId: yup
    .string()
    .when(['$collectMetric'], ([collectMetric], schema) =>
      collectMetric ? makeRequired(schema) : schema.strip()
    ),
  intervalReminderOffset: number.when(
    ['$collectReminderOffset'],
    ([collectReminderOffset], schema) =>
      collectReminderOffset ? makeNullable(schema.integer()) : schema.strip()
  ),
});

const AdminFundraiserEditModalGoalView = ({ fundraiserId, onDirtyChange, onCancel }) => {
  const { addToast } = useGlobal();

  const { data } = useQuery(GET_FUNDRAISER_GOAL, { variables: { id: fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);

  const allowedMetrics = useMemo(() => campaign?.assignedActivityMetrics ?? [], [campaign]);
  const collectEstimate =
    campaign?.isPerformanceEnabled && campaign?.performanceTrackingLevel === 'fundraiser';
  const collectMetric =
    collectEstimate && !campaign?.isMetricLabelLocked && allowedMetrics?.length > 1;
  const collectReminderOffset = collectEstimate && campaign?.performanceMode === 'interval';

  const { control, formState, handleSubmit, reset, watch } = useForm({
    context: {
      collectEstimate,
      collectMetric,
      collectReminderOffset,
      commitmentAmount: fundraiser?.commitmentAmount,
    },
    resolver: yupResolver(validationSchema),
  });
  const { errors, isDirty } = formState;
  const unlockedActivityMetricId = watch('unlockedActivityMetricId');

  const metricOptions = useMemo(() => {
    if (!collectMetric) return null;
    return allowedMetrics?.map((metric) => ({
      label: capitalize(metric.plural),
      value: metric.id,
    }));
  }, [allowedMetrics, collectMetric]);

  const metricLabelPlural = useMemo(() => {
    if (!campaign?.isPerformanceEnabled) return null;
    if (!collectMetric) return campaign?.metricLabelPlural;
    return (
      campaign?.assignedActivityMetrics?.find((x) => x.id === unlockedActivityMetricId)?.plural ||
      fundraiser?.performanceSettings?.metricLabelPlural
    );
  }, [
    campaign,
    collectMetric,
    unlockedActivityMetricId,
    fundraiser?.performanceSettings?.metricLabelPlural,
  ]);

  const performanceEstimateHelpText = useMemo(() => {
    if (!campaign?.isPerformanceEnabled) return null;
    const dateSpan = formatDateRange(campaign?.performanceStartDate, campaign?.performanceEndDate);
    return `Estimated ${metricLabelPlural} you expect to record for the duration of the campaign (${dateSpan})`;
  }, [metricLabelPlural, campaign]);

  const reminderOptions = useMemo(() => {
    if (!collectReminderOffset) return null;

    const duration = DateTime.fromISO(campaign?.performanceEndDate)
      .diff(DateTime.fromISO(campaign?.performanceStartDate))
      .as('days');

    const durationLeft = DateTime.fromISO(campaign?.performanceEndDate).diffNow().as('days');

    return [
      { label: 'Daily', value: 1 },
      ...[
        { label: 'Every few days', value: 3 },
        { label: 'Weekly', value: 7 },
      ].filter(
        ({ value }) =>
          (parseNumber(value) + 1 < duration && parseNumber(value) + 1 < durationLeft) ||
          parseNumber(value) === parseNumber(fundraiser?.intervalReminderOffset)
      ),
      { label: 'At the end of the campaign', value: null },
    ];
  }, [campaign, fundraiser?.intervalReminderOffset, collectReminderOffset]);

  const [updateFundraiser] = useMutation(UPDATE_FUNDRAISER_GOAL);
  const onSubmit = useCallback(
    async (values) => {
      try {
        await updateFundraiser({ variables: { data: { id: fundraiserId, ...values } } });

        addToast({
          id: 'fundraiser-edit',
          type: 'success',
          duration: 'short',
          message: 'Your changes have been saved',
        });
      } catch (err) {
        if (config('/debug')) console.error(err);
        addToast({
          id: 'fundraiser-edit',
          type: 'error',
          duration: 'long',
          message: 'There was an error saving your changes, please try again.',
        });
      }
    },
    [updateFundraiser, fundraiserId, addToast]
  );

  useEffect(
    () =>
      reset({
        monetaryGoal: fundraiser?.monetaryGoal ?? '',
        performanceEstimate: fundraiser?.performanceEstimate ?? '',
        unlockedActivityMetricId: fundraiser?.unlockedActivityMetricId,
        intervalReminderOffset: fundraiser?.intervalReminderOffset,
      }),
    [reset, fundraiser]
  );

  // Prevent navigating with unsaved changes
  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!fundraiser) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToFormError)}>
      <FormTitle>Goals</FormTitle>
      <FormFieldGroup>
        <FormNode>
          <FormLabel htmlFor="monetaryGoal" isRequired>
            Fundraising goal
          </FormLabel>
          <Controller
            control={control}
            name="monetaryGoal"
            render={({ field, fieldState }) => (
              <FormCurrencyInput
                type="text"
                status={fieldState.error ? 'error' : 'default'}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="monetaryGoal"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        {collectMetric && (
          <FormNode>
            <FormLabel
              id="unlockedActivityMetricIdLabel"
              htmlFor="unlockedActivityMetricId"
              isRequired
            >
              Activity metric
            </FormLabel>
            <Controller
              control={control}
              name="unlockedActivityMetricId"
              render={({ field, fieldState }) => (
                <FormSelect
                  isClearable={false}
                  isSearchable={false}
                  options={metricOptions}
                  status={fieldState.error ? 'error' : 'default'}
                  aria-labelledby="unlockedActivityMetricIdLabel"
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="unlockedActivityMetricId"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
        )}
        {collectEstimate && (
          <FormNode>
            <FormLabel htmlFor="performanceEstimate" isRequired>
              {`${startCase(metricLabelPlural)} goal`}
            </FormLabel>
            <Controller
              control={control}
              name="performanceEstimate"
              render={({ field, fieldState }) => (
                <FormNumberInput
                  type="text"
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
            <FormNote>{performanceEstimateHelpText}</FormNote>
            <ErrorMessage
              errors={errors}
              name="performanceEstimate"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
        )}
        {collectReminderOffset && (
          <FormNode>
            <FormLabel htmlFor="intervalReminderOffset">
              Remind me to log my{' '}
              <span className="font-medium">
                {fundraiser.performanceSettings.metricLabelPlural}
              </span>
            </FormLabel>
            <Controller
              control={control}
              name="intervalReminderOffset"
              render={({ field }) => (
                <FormRadioGroup options={reminderOptions} isRequired {...field} />
              )}
            />
          </FormNode>
        )}
      </FormFieldGroup>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AdminFundraiserEditModalGoalView;

AdminFundraiserEditModalGoalView.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
