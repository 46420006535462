import defer from 'lodash/defer';

export default function scrollToFormError() {
  // Need to defer to allow the rendering to complete
  defer(() => {
    // Scroll the first error note into view
    const errorNode = document.querySelector('[data-formnote-status="error"]');
    if (errorNode) {
      errorNode.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // Focus it's form control if able
      const formControl = errorNode.parentNode.querySelector('input, textarea, select, button');
      if (formControl) formControl.focus();
    }
  });
}
