import { useMemo, useCallback } from 'react';
import { gql, useQuery, makeVar, useReactiveVar } from '@apollo/client';
import Router from 'next/router';

export const PARTICIPANT_QUESTION_FIELDS = gql`
  fragment ParticipantQuestionFields on Question {
    id
    label
    helpText
    isEnabled
    isRequired
    type
    sort
    config
    options(order: "sort", where: { isEnabled: true }) {
      id
      label
      sort
    }
  }
`;

const GET_CAMPAIGN_JOIN_DATA = gql`
  ${PARTICIPANT_QUESTION_FIELDS}

  query GetCampaignJoinData($id: String!) {
    findCampaigns(id: $id) {
      id
      accentColor
      activeFeatures {
        activityTracking
        join
        teams
      }
      assignedActivityMetrics {
        id
        plural
        category
      }
      campaignLogo
      discountCodeCount
      enabledFeatures
      fundraisingType
      campaignHeaderBackgroundImage
      fundraiserDefaultHeaderAvatarImage
      fundraiserDefaultHeaderBackgroundImage
      fundraiserDefaultMonetaryGoal
      isMetricLabelLocked
      metricLabelPlural
      name
      participantQuestions(order: "sort", where: { isEnabled: true }) {
        ...ParticipantQuestionFields
      }
      ownerOrganization {
        id
        logo
        name
        paymentProcessorConfig
      }
      performanceEndDate
      performanceMode
      performanceStartDate
      primaryColor
      registrationDescription
      registrationTypes(order: "sort", where: { isEnabled: true, type: "participant" }) {
        id
        allQuestions(order: "sort", where: { isEnabled: true }) {
          ...ParticipantQuestionFields
        }
        amount
        description
        name
        qtyUsed
        qtyTotal
        sort
      }
      teamDefaultHeaderAvatarImage
      teamDefaultHeaderBackgroundImage
      teamDefaultMonetaryGoal
      teams(order: "name") {
        id
        avatarImage
        name
      }
      timezone
    }
  }
`;

export const CAMPAIGN_JOIN_ID_VAR = makeVar(null);

export const CAMPAIGN_JOIN_FORMDATA_VAR = makeVar({});

export const useCampaignJoin = () => {
  const campaignId = useReactiveVar(CAMPAIGN_JOIN_ID_VAR);
  const query = useQuery(GET_CAMPAIGN_JOIN_DATA, {
    variables: { id: campaignId },
    fetchPolicy: 'cache-first',
    skip: !campaignId,
  });
  const campaign = useMemo(() => query.data?.findCampaigns[0], [query.data]);

  const formData = useReactiveVar(CAMPAIGN_JOIN_FORMDATA_VAR);

  const modules = useMemo(
    () => ({
      tickets: campaign?.registrationTypes?.length > 0,
      team: !!campaign?.activeFeatures.teams,
      fundraiser: !!campaign?.activeFeatures.join,
      strava: !!campaign?.enabledFeatures?.includes('strava'),
    }),
    [campaign]
  );

  const routes = useMemo(() => {
    const base = `/c/${campaignId}`;
    return {
      exit: base,
      welcome: `${base}/join`,
      fundraiserReturn: `${base}/join/fundraiser-return`,
      tickets: `${base}/join/tickets`,
      teams: `${base}/join/team`,
      teamCreate: `${base}/join/create-team`,
      fundraiserCreate: `${base}/join/create-fundraiser`,
      submit: `${base}/join/submit`,
      stravaConnect: `${base}/join/connect-strava`,
    };
  }, [campaignId]);

  return {
    ...query,

    // Data structures
    campaign,
    formData,
    modules,
    routes,

    // Methods
    onNavigate: useCallback(
      (key) => {
        const route = routes[key];
        if (!route) throw new Error(`Unknown route: ${key}`);
        Router.push(route);
      },
      [routes]
    ),

    updateFormData: useCallback(
      (changes) => CAMPAIGN_JOIN_FORMDATA_VAR({ ...CAMPAIGN_JOIN_FORMDATA_VAR(), ...changes }),
      []
    ),

    resetFormData: useCallback((state = {}) => CAMPAIGN_JOIN_FORMDATA_VAR(state), []),
  };
};
