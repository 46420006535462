import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback } from 'react';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import omitBy from 'lodash/omitBy';
import * as yup from 'yup';

import config from 'config';
import { useGlobal } from 'context/Global';
import useCustomQuestionFields from 'hooks/useCustomQuestionFields';
import scrollToFormError from 'lib/scrollToFormError';
import Button from 'components/common/Button';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormTitle from 'components/form/FormTitle';
import { PARTICIPANT_QUESTION_FIELDS } from 'context/CampaignJoin';

const GET_PARTICIPANT_DETAILS = gql`
  ${PARTICIPANT_QUESTION_FIELDS}
  query GetFundraiserDetails($campaignId: String!, $participantId: String!) {
    findCampaigns(id: $campaignId) {
      id
      participants(where: { id: $participantId }) {
        id

        registrationType {
          id
          allQuestions(order: "sort") {
            ...ParticipantQuestionFields
          }
        }
        answers {
          id
          questionId
          answer
        }
      }
      participantQuestions(order: "sort") {
        ...ParticipantQuestionFields
        assigningRegistrationTypes {
          id
        }
      }
    }
  }
`;

const ANSWER_QUESTION = gql`
  mutation AnswerQuestion($audienceId: String!, $questionId: String!, $answer: SequelizeJSON!) {
    answerQuestion(audienceId: $audienceId, questionId: $questionId, answer: $answer)
  }
`;

const AdminFundraiserEditModalDetailsView = ({
  participantId,
  campaignId,
  onDirtyChange,
  onCancel,
}) => {
  const client = useApolloClient();
  const { addToast } = useGlobal();

  const { data, refetch } = useQuery(GET_PARTICIPANT_DETAILS, {
    variables: {
      campaignId,
      participantId,
    },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const participant = useMemo(() => campaign?.participants[0], [campaign]);

  const questions = useMemo(() => {
    if (!participant) return [];
    const ticketOption = participant.registrationType;
    const currentQuestions = ticketOption
      ? ticketOption.allQuestions
      : campaign.participantQuestions;
    const answers = participant.answers ?? [];

    return currentQuestions.filter((question) => {
      // Include the question if the fundraiser has answered it even if it is disabled
      if (answers.find((x) => x.questionId === question.id)) {
        return true;
      }
      if (question.isEnabled) return true;
      return false;
    });
  }, [participant, campaign]);

  const responses = useMemo(() => {
    if (!questions) return null;
    const answers = {};
    questions.forEach((question) => {
      const questionAnswer = participant.answers.find((x) => x.questionId === question.id);
      answers[`question:${question.id}`] = questionAnswer?.answer;
    });
    return answers;
  }, [questions, participant]);

  const { validationRules, defaultValues, fieldNodes } = useCustomQuestionFields({
    questions,
    values: responses,
  });

  const validationSchema = useMemo(() => yup.object(validationRules), [validationRules]);

  const formMethods = useForm({ resolver: yupResolver(validationSchema) });
  const { handleSubmit, reset, formState } = formMethods;
  const { isDirty } = formState;

  const [answerQuestion] = useMutation(ANSWER_QUESTION);
  const onSubmit = useCallback(
    async (values) => {
      try {
        const questionAnswers = omitBy(values, (v) => v === null);

        await Promise.all(
          Object.entries(questionAnswers).map(([k, answer]) =>
            answerQuestion({
              variables: {
                questionId: k.replace('question:', ''),
                audienceId: participantId,
                answer,
              },
            })
          )
        );
        await refetch();
        client.refetchQueries({
          include: ['GetParticipantSignupPage'],
        });

        addToast({
          id: 'fundraiser-edit',
          type: 'success',
          duration: 'short',
          message: 'Your changes have been saved',
        });
      } catch (err) {
        if (config('/debug')) console.error(err);
        addToast({
          id: 'fundraiser-edit',
          type: 'error',
          duration: 'long',
          message: 'There was an error saving your changes, please try again.',
        });
      }
    },
    [participantId, answerQuestion, refetch, addToast, client]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // Prevent navigating with unsaved changes
  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!participant) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToFormError)}>
      <FormTitle>Details</FormTitle>
      <FormProvider {...formMethods}>
        <FormFieldGroup>{fieldNodes}</FormFieldGroup>
      </FormProvider>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

AdminFundraiserEditModalDetailsView.propTypes = {
  campaignId: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AdminFundraiserEditModalDetailsView.defaultProps = {};

export default AdminFundraiserEditModalDetailsView;
