import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useToasts from 'hooks/useToasts';
import { useAuth } from 'context/Auth';
import { makeRequired, makeNullable, phone, email } from 'lib/validators';
import getAddressFieldsConfig from 'lib/getAddressFieldsConfig';
import scrollToFormError from 'lib/scrollToFormError';
import Button from 'components/common/Button';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormNote from 'components/form/FormNote';
import FormInput from 'components/form/FormInput';
import FormTitle from 'components/form/FormTitle';
import FormLabel from 'components/form/FormLabel';
import FormPhoneInput from 'components/form/FormPhoneInput';
import Tiles from 'components/common/Tiles';

const GET_PARTICIPANT_DETAILS = gql`
  query GetParticipantContactInfo($campaignId: String!, $participantId: String!) {
    findCampaigns(id: $campaignId) {
      id
      supportedFeatures {
        event
      }

      participants(where: { id: $participantId }) {
        id
        userId
        type
        email
        phone
        lastName
        firstName
        address1
        address2
        city
        stateProv
        postalCode
        country
        registrationType {
          id
          collectEmail
          collectPhone
          collectAddress
        }
      }
    }
  }
`;

const UPDATE_PARTICIPANT = gql`
  mutation UpdateTicket($data: FundraiserInput!) {
    updateParticipant(Fundraiser: $data) {
      firstName
      lastName
      email
      phone
      address1
      address2
      city
      stateProv
      postalCode
      country
    }
  }
`;

const AdminFundraiserEditContactView = ({ participantId, campaignId, onDirtyChange, onCancel }) => {
  const client = useApolloClient();
  const toast = useToasts('editParticipant:contact');
  const { user } = useAuth();

  const { data: ticketData } = useQuery(GET_PARTICIPANT_DETAILS, {
    variables: {
      campaignId,
      participantId,
    },
  });

  const campaign = useMemo(() => ticketData?.findCampaigns[0], [ticketData]);
  const participant = useMemo(() => campaign?.participants[0], [campaign]);
  const isEvent = campaign?.supportedFeatures.event;
  const isFundraiser = participant?.type === 'fundraiser';
  const isUnclaimedFundraiser = isFundraiser && !participant?.userId;
  const userIsFundraiser = useMemo(() => participant?.userId === user.id, [participant, user]);

  const addressFieldsConfig = useMemo(
    () =>
      getAddressFieldsConfig({
        validator: participant?.registrationType?.collectAddress ?? 'off',
        values: participant ?? {},
      }),
    [participant]
  );

  const fieldDefs = useMemo(() => {
    // This form only works for event-based campaigns
    if (!isEvent || !participant) return {};
    const { collectEmail, collectPhone } = participant.registrationType ?? {};
    return {
      ...(!isFundraiser || isUnclaimedFundraiser ? { firstName: { isRequired: true } } : {}),
      ...(!isFundraiser || isUnclaimedFundraiser ? { lastName: { isRequired: true } } : {}),
      ...(collectEmail !== 'off' && (!isFundraiser || isUnclaimedFundraiser)
        ? { email: { isRequired: isUnclaimedFundraiser || collectEmail === 'required' } }
        : {}),
      ...(collectPhone !== 'off' && { phone: { isRequired: collectPhone === 'required' } }),
    };
  }, [isEvent, isFundraiser, isUnclaimedFundraiser, participant]);

  // Assemble validators for all the questions
  const validationSchema = useMemo(() => {
    const fields = {
      ...(participant?.registrationType?.collectAddress !== 'off' &&
        addressFieldsConfig.validationRules),
    };

    Object.entries(fieldDefs).forEach(([k, v]) => {
      const modifier = v.isRequired ? makeRequired : makeNullable;
      fields[k] = modifier(k === 'phone' ? phone : k === 'email' ? email : yup.string());
    });

    return yup.object().shape(fields);
  }, [addressFieldsConfig, fieldDefs, participant?.registrationType]);

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset, formState, control } = formMethods;
  const { isDirty, errors } = formState;

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT, {
    onCompleted: () => toast.success('Your changes have been saved'),
    onError: () => toast.error('There was an error saving your changes, please try again.'),
  });

  useEffect(() => {
    reset({ ...addressFieldsConfig.defaultValues });
  }, [reset, addressFieldsConfig]);

  // Prevent navigating with unsaved changes
  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!participant) return null;

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        await updateParticipant({
          variables: {
            data: {
              id: participantId,
              ...values,
            },
          },
        });
        reset(values);
        client.refetchQueries({
          include: ['GetParticipantOverview'],
        });
      }, scrollToFormError)}
    >
      <FormTitle>Contact</FormTitle>
      <FormProvider {...formMethods}>
        <FormFieldGroup>
          {fieldDefs.firstName && (
            <Tiles columns={2} spacing="xs" className="mb-4">
              <FormNode>
                <FormLabel htmlFor="firstName" isRequired={fieldDefs.firstName.isRequired}>
                  First Name
                </FormLabel>
                <Controller
                  control={control}
                  name="firstName"
                  defaultValue={participant?.firstName ?? ''}
                  render={({ field, fieldState }) => (
                    <FormInput {...field} status={fieldState.error ? 'error' : 'default'} />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => <FormNote status="error">{message}</FormNote>}
                />
              </FormNode>
              <FormNode>
                <FormLabel htmlFor="lastName" isRequired={fieldDefs.lastName.isRequired}>
                  Last Name
                </FormLabel>
                <Controller
                  control={control}
                  name="lastName"
                  defaultValue={participant?.lastName ?? ''}
                  render={({ field, fieldState }) => (
                    <FormInput {...field} status={fieldState.error ? 'error' : 'default'} />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => <FormNote status="error">{message}</FormNote>}
                />
              </FormNode>
            </Tiles>
          )}
          {fieldDefs.email && (
            <FormNode>
              <FormLabel htmlFor="email" isRequired={fieldDefs.email.isRequired}>
                Email
              </FormLabel>
              <Controller
                control={control}
                name="email"
                defaultValue={participant?.email ?? ''}
                render={({ field, fieldState }) => (
                  <FormInput {...field} status={fieldState.error ? 'error' : 'default'} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <FormNote status="error">{message}</FormNote>}
              />
            </FormNode>
          )}

          {isFundraiser && !userIsFundraiser && !isUnclaimedFundraiser ? (
            <p className="text-sm">
              Note: As this participant has access to their own account, only they can edit their
              name and email address.
            </p>
          ) : null}
          {fieldDefs.phone && (
            <FormNode>
              <FormLabel isRequired={fieldDefs.phone.isRequired}>Phone</FormLabel>
              <Controller
                control={control}
                name="phone"
                defaultValue={participant?.phone ?? ''}
                render={({ field, fieldState }) => (
                  <FormPhoneInput status={fieldState.error ? 'error' : 'default'} {...field} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ message }) => <FormNote status="error">{message}</FormNote>}
              />
            </FormNode>
          )}
          {addressFieldsConfig.renderFields({ label: 'Address 1' })}
        </FormFieldGroup>
      </FormProvider>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

AdminFundraiserEditContactView.propTypes = {
  campaignId: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AdminFundraiserEditContactView.defaultProps = {};

export default AdminFundraiserEditContactView;
