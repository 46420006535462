import { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, gql, useApolloClient } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';

import config from 'config';
import { formatCurrency } from 'lib/formatters';
import useToasts from 'hooks/useToasts';
import FormTitle from 'components/form/FormTitle';
import FormNote from 'components/form/FormNote';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormSelect from 'components/form/FormSelect';
import Button from 'components/common/Button';

const GET_TICKET = gql`
  query getTicketTypeFields($campaignId: String!, $participantId: String!) {
    findCampaigns(id: $campaignId) {
      id
      registrationTypes(where: { type: "participant" }, order: "sort") {
        id
        name
        isEnabled
        qtyRemaining
        commitmentAmount
        isCommitmentEnabled
      }
      participants(where: { id: $participantId }) {
        id
        type
        ticketStatus
        commitmentStatus
        registrationTypeId
        sponsorId
      }
    }
  }
`;

const EDIT_TICKET = gql`
  mutation EditTicket($data: FundraiserInput!) {
    updateParticipant(Fundraiser: $data) {
      id
      registrationTypeId
    }
  }
`;

const AdminFundraiserEditModalTicketView = ({
  campaignId,
  participantId,
  onDirtyChange,
  onCancel,
}) => {
  const client = useApolloClient();
  const toast = useToasts('edit-ticket');

  const [editTicket] = useMutation(EDIT_TICKET, {
    onCompleted: () => toast.success('Your changes have been saved'),
    onError: (err) => {
      if (err.message.startsWith('RegistrationTypeExhausted:')) {
        toast.error('Cannot reactivate, ticket type is now sold out');
        return;
      }
      toast.error('There was an error saving your changes. Please try again.');
    },
  });

  const { data } = useQuery(GET_TICKET, {
    variables: { campaignId, participantId },
  });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const participant = useMemo(() => campaign?.participants[0], [campaign]);
  const isCancelled = useMemo(() => participant?.ticketStatus === 'cancelled', [participant]);
  const isFundraiser = participant?.type === 'fundraiser';

  const regTypes = useMemo(() => {
    if (!campaign) return [];
    return campaign?.registrationTypes.filter(
      (x) => x.id === participant.registrationTypeId || x.isEnabled
    );
  }, [campaign, participant]);

  const hasSponsor = !!participant?.sponsorId;
  const hasCurrentCommitment = !!participant?.commitmentStatus;
  const hasCommitmentOptions = useMemo(
    () => regTypes.some((x) => x.isCommitmentEnabled),
    [regTypes]
  );

  const ticketOptions = campaign?.registrationTypes?.reduce((result, regType) => {
    // Exclude disabled optiosn that aren't already selected by the participant
    if (regType.id === participant.registrationTypeId || regType.isEnabled) {
      const hasCommitment = regType.isCommitmentEnabled;
      const isSoldOut = regType.id !== participant.registrationTypeId && regType.qtyRemaining === 0;
      const isDisabled = (!hasCurrentCommitment && hasCommitment) || isSoldOut;
      const getLabel = () => {
        if (regType.isCommitmentEnabled) {
          return `${regType.name} - ${formatCurrency(regType.commitmentAmount)} commitment`;
        }
        if (regType.id !== participant.registrationTypeId && regType.qtyRemaining === 0) {
          return `${regType.name} - SOLD OUT`;
        }
        return regType.name;
      };

      result.push({
        label: getLabel(),
        value: regType.id,
        isDisabled,
        isSoldOut,
      });
    }
    return result;
  }, []);

  const { handleSubmit, control, reset, formState, watch } = useForm();
  const { isDirty } = formState;

  const selectedTxId = watch('registrationTypeId');

  const removingCommitment = useMemo(
    () =>
      isFundraiser &&
      hasCurrentCommitment &&
      !regTypes.find((x) => x.id === selectedTxId)?.isCommitmentEnabled,
    [isFundraiser, hasCurrentCommitment, regTypes, selectedTxId]
  );

  const ticketOptionNote = useMemo(() => {
    if (isCancelled) {
      return 'This participant’s ticket is canceled - their ticket option cannot be changed';
    }
    if (hasSponsor && hasCommitmentOptions) {
      return 'Sponsor tickets are unable to have a fundraising commitment. To assign a ticket option with a fundraising commitment to a participant with a sponsor ticket, please cancel their current ticket and ask them to re-register.';
    }
    if (!hasCurrentCommitment && hasCommitmentOptions) {
      return 'To assign a ticket option with a fundraising commitment to a participant that doesn’t previously have a commitment, please cancel their current ticket and ask them to re-register.';
    }
    if (isFundraiser && removingCommitment) {
      return (
        <span>
          Since this ticket option does not have a fundraising commitment, the participant&apos;s
          current commitment will be removed. This <b>cannot be undone.</b>
        </span>
      );
    }
    return null;
  }, [
    isCancelled,
    isFundraiser,
    hasSponsor,
    hasCommitmentOptions,
    hasCurrentCommitment,
    removingCommitment,
  ]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        await editTicket({
          variables: { data: { id: participantId, ...values } },
        });
        client.refetchQueries({
          include: [
            'GetFundraiserCommitment',
            'GetParticipantSignupPage',
            'GetParticipantFundraising',
          ],
        });
      } catch (err) {
        if (config('/debug')) console.error(err);
      }
    },
    [editTicket, participantId, client]
  );

  useEffect(
    () =>
      reset({
        registrationTypeId: participant?.registrationTypeId,
      }),
    [reset, participant]
  );

  // Prevent navigating with unsaved changes
  useEffect(() => onDirtyChange(isDirty), [onDirtyChange, isDirty]);

  if (!participant) return null;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pb-64 md:pb-0">
      <FormTitle className="mb-2">Ticket</FormTitle>
      <div className="text-sm text-gray-600 mb-8">
        <b>Note:</b> Ticket changes will not generate a charge or refund. Any payment adjustments
        need to be made separately in Stripe.
      </div>

      <FormNode>
        <FormLabel id="registrationTypeIdLabel" htmlFor="registrationTypeId">
          Ticket option
        </FormLabel>
        <Controller
          control={control}
          name="registrationTypeId"
          render={({ field }) => (
            <FormSelect
              placeholder="Search tickets..."
              options={ticketOptions}
              disabled={isCancelled}
              status={removingCommitment ? 'info' : null}
              isClearable={false}
              aria-labelledby="registrationTypeIdLabel"
              {...field}
            />
          )}
        />
        {ticketOptionNote && <FormNote>{ticketOptionNote}</FormNote>}
      </FormNode>
      <div className="mt-6 md:mt-8 grid grid-cols-2 md:grid-cols-1 gap-x-4">
        <Button
          as="button"
          type="button"
          color="gray-300"
          padding="sm"
          className="font-medium w-full md:hidden"
          onClick={onCancel}
          outline
        >
          Cancel
        </Button>
        <div className="flex justify-end">
          <Button
            as="button"
            type="submit"
            color="primary"
            padding="sm"
            className="font-medium w-full md:w-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

AdminFundraiserEditModalTicketView.propTypes = {
  campaignId: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AdminFundraiserEditModalTicketView.defaultProps = {};

export default AdminFundraiserEditModalTicketView;
